import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { Controller, FieldError } from 'react-hook-form';

type Props = {
	name: string;
} & TextFieldProps;

export const FormTextField = ({ name, ...textFieldProps }: Props) => {
	return (
		<Controller
			name={name}
			render={({ field: { value, ...field }, fieldState: { error } }) => {
				let helperText: string | undefined;
				if (Array.isArray(error) && error.length) {
					const firstErrorMessage = (error as FieldError[]).find((e) => e?.message);
					if (firstErrorMessage?.message) helperText = firstErrorMessage.message;
				} else if (error?.message) {
					helperText = error.message;
				}

				return (
					<TextField
						{...textFieldProps}
						{...field}
						value={value ?? ''}
						error={Boolean(error)}
						helperText={helperText}
					/>
				);
			}}
		/>
	);
};
