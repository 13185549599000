import React, { useState } from 'react';
import { Alert, Collapse, IconButton, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Filter } from 'lucide-react';
import { FormattedDate } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Back } from '@/atoms/back';
import { Link } from '@/atoms/link-component';
import { Row } from '@/atoms/row';
import { useBooleanFilterSelect } from '@/organisms/backoffice/backoffice-boolean-filter-select';
import { BackofficeEvalGroundTruthCol } from '@/organisms/backoffice/backoffice-eval-ground-truth-col';
import { BackofficeMailboxAttachmentCol } from '@/organisms/backoffice/backoffice-mailbox-attachment-col';
import { BackofficeProcessingResultCol } from '@/organisms/backoffice/backoffice-processing-result-col';
import { links } from '@/paths';
import { trpc } from '@/trpc/client';
import { usePagination, usePaginationRowCount } from '@/utils/use-pagination';

import { RouterOutput } from '../../../../../functions/src/trpc/app-router';

type Item = RouterOutput['backoffice']['organizations']['mailbox']['list']['data'][number];

export const BackofficeOrganizationsMailbox = () => {
	const { id: organizationId } = useParams<{ id: string }>();
	if (!organizationId) throw new Error('No organization id');

	const [filtersOpen, setFiltersOpen] = useState(false);

	const { page, pageSize, paginationModel, onPaginationModelChange } = usePagination({ defaultPageSize: 20 });

	const filterEvalGroundTruth = useBooleanFilterSelect({ id: 'evalGroundTruth', label: 'Eval Ground Truth' });

	const { data: organization, isError: organizationIsError } = trpc.backoffice.organizations.get.useQuery({
		id: organizationId,
	});
	const { data, isError, isLoading } = trpc.backoffice.organizations.mailbox.list.useQuery({
		organizationId,
		evalGroundTruth: filterEvalGroundTruth.booleanValue,
		page,
		pageSize,
	});

	const columns: GridColDef<Item>[] = [
		{
			field: 'subject',
			headerName: 'Subject',
			flex: 1,
			renderCell({ value, row: { id, from } }) {
				return (
					<Row sx={{ py: 0.5 }}>
						<Link
							to={links.backoffice.organizations.detail.mailbox.detail
								.replace(':id', organizationId)
								.replace(':messageId', id)}
							color="inherit"
						>
							<span style={{ fontSize: 12 }}>{from}</span>
							<br />
							{value}
						</Link>
					</Row>
				);
			},
		},
		{
			field: 'receivedAt',
			headerName: 'Received At',
			width: 160,
			renderCell({ value }) {
				return <FormattedDate value={value} />;
			},
		},
		{
			field: 'attachments',
			headerName: 'Attachments',
			width: 200,
			renderCell({ row: { attachments } }) {
				return <BackofficeMailboxAttachmentCol attachments={attachments} />;
			},
		},
		{
			field: 'processingResults',
			headerName: 'Processing result',
			width: 220,
			renderCell({ row: { processingResults } }) {
				return <BackofficeProcessingResultCol value={processingResults} />;
			},
		},
		{
			field: 'evalGroundTruth',
			headerName: 'Eval Ground Truth',
			width: 180,
			renderCell({ row: { id, evalGroundTruth } }) {
				return <BackofficeEvalGroundTruthCol emailMessageId={id} value={evalGroundTruth} />;
			},
		},
	];

	const rowCount = usePaginationRowCount(data?.pagination.count);

	return (
		<>
			<Back to={links.backoffice.organizations.detail.ROOT.replace(':id', organizationId)} />

			<Row sx={{ mt: 1, mb: 1 }} gap={1}>
				<Typography variant="h2">Mailbox of {organization?.name ?? ''}</Typography>

				<IconButton onClick={() => setFiltersOpen((prev) => !prev)} color={filtersOpen ? 'primary' : 'default'}>
					<Filter />
				</IconButton>
			</Row>

			<Collapse in={filtersOpen}>{filterEvalGroundTruth.select}</Collapse>

			{(organizationIsError || isError) && <Alert severity="error">There was an error</Alert>}

			<DataGrid
				rows={data?.data ?? []}
				loading={isLoading}
				columns={columns}
				density="compact"
				rowCount={rowCount}
				paginationModel={paginationModel}
				paginationMode="server"
				onPaginationModelChange={onPaginationModelChange}
				sx={{ mt: 1 }}
				autoHeight
				getRowHeight={() => 'auto'}
			/>
		</>
	);
};
