import React, { useMemo, useState } from 'react';
import { Autocomplete, Box, Portal, TextField } from '@mui/material';
import { first } from 'lodash';

import { trpc } from '@/trpc/client';

import { RouterOutput } from '../../../../functions/src/trpc/app-router';
import { BackofficeToolCreate } from './backoffice-tool-create';

type Tool = RouterOutput['backoffice']['tools']['list']['data'][number];

type Props = {
	valueId: string;
	onChange: (value: string | undefined) => void;
};

const createNewToolOption = { id: 'create-new', name: 'Create New Tool' };

export const BackofficeToolSelect = ({ valueId, onChange }: Props) => {
	const [inputValue, setInputValue] = useState('');
	const [createOpen, setCreateOpen] = useState(false);

	const { data: list } = trpc.backoffice.tools.list.useQuery(
		{ search: inputValue },
		{ enabled: inputValue.length > 2 },
	);
	const { data: value } = trpc.backoffice.tools.list.useQuery({ id: valueId }, { enabled: Boolean(valueId) });

	const opts = useMemo(() => {
		const options = list?.data ?? [];

		const valueIsInOptions = options.some((option) => option.id === valueId);
		if (value?.data.length && !valueIsInOptions) {
			return [first(value.data), ...options, createNewToolOption];
		}

		return [...options, createNewToolOption];
	}, [list, value, valueId]);

	return (
		<>
			<Autocomplete
				value={value?.data.length ? first(value.data) : null}
				sx={{ width: 300 }}
				options={opts}
				autoHighlight
				getOptionLabel={(option) => getOptionLabel(option)}
				filterOptions={(x) => x}
				isOptionEqualToValue={(option, val) => option.id === val.id}
				renderOption={(props, option) => {
					const { ...optionProps } = props;
					return (
						<Box key={option.id} component="li" {...optionProps}>
							{option.id === 'create-new' ? '-- Create New Tool ---' : getOptionLabel(option)}
						</Box>
					);
				}}
				onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue);
				}}
				renderInput={(params) => <TextField {...params} label="Tool" size="small" />}
				onChange={(_, value) => {
					if (value?.id === 'create-new') {
						setCreateOpen(true);
					} else {
						onChange(value?.id ?? undefined);
					}
				}}
			/>

			<BackofficeToolCreate
				open={createOpen}
				onClose={(createdToolId) => {
					setCreateOpen(false);
					if (createdToolId) onChange(createdToolId);
				}}
			/>
		</>
	);
};

function getOptionLabel({ name }: Pick<Tool, 'name'>) {
	return `${name}`;
}
