import React from 'react';
import { Chip, Link as MUILink, Stack } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';

import { getAttachmentLink } from '@/utils/attachment-file-link';

import { RouterOutput } from '../../../../functions/src/trpc/app-router';

type Item = RouterOutput['backoffice']['organizations']['mailbox']['list']['data'][number];

type Props = {
	attachments: Item['attachments'];
};

export const BackofficeMailboxAttachmentCol = ({ attachments }: Props) => {
	const confirm = useConfirm();

	return (
		<Stack gap={0.25} sx={{ py: 0.5 }}>
			{(attachments ?? []).map(({ filename, invoiceId, id }) => {
				return (
					<MUILink
						href={getAttachmentLink(id, true)}
						target="_blank"
						onClick={(e) => {
							e.preventDefault();

							confirm({
								title: filename,
								content: (
									<>
										<iframe
											src={getAttachmentLink(id, true)}
											style={{ border: 0, width: '100%', height: '80vh' }}
										/>
									</>
								),
								hideCancelButton: true,
								dialogProps: {
									maxWidth: 'lg',
								},
							});
						}}
					>
						<Chip label={filename} size="small" variant={invoiceId ? 'filled' : 'outlined'} />
					</MUILink>
				);
			})}
		</Stack>
	);
};
