import React, { useState } from 'react';
import { Box, Button, Skeleton, TextField, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { create } from 'lodash';

import { Back } from '@/atoms/back';
import { FormattedDatetime } from '@/atoms/formatted-datetime';
import { Row } from '@/atoms/row';
import { BackofficeToolCreate } from '@/molecules/backoffice/backoffice-tool-create';
import { links } from '@/paths';
import { trpc } from '@/trpc/client';
import { eerie } from '@/utils/theme';
import { usePagination, usePaginationRowCount } from '@/utils/use-pagination';

import { RouterOutput } from '../../../../../functions/src/trpc/app-router';

type Item = RouterOutput['backoffice']['tools']['list']['data'][number];

export const BackofficeTools = () => {
	const [createOpen, setCreateOpen] = useState(false);
	const [search, setSearch] = useState('');

	const { page, pageSize, paginationModel, onPaginationModelChange } = usePagination({ defaultPageSize: 20 });

	const { data, isLoading } = trpc.backoffice.tools.list.useQuery({
		search: search.length > 2 ? search : undefined,
		page,
		pageSize,
	});

	const rowCount = usePaginationRowCount(data?.pagination.count);

	const columns: GridColDef<Item>[] = [
		{
			field: 'logoUrl',
			headerName: 'Logo',
			flex: 1,
			renderCell({ value, row: { name } }) {
				const logo = (
					<Box
						sx={{
							width: 32,
							height: 32,
							borderRadius: '50%',
							backgroundImage: value ? `url('${value}')` : 'none',
							backgroundSize: '20px auto',
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat',
							backgroundColor: eerie['400'],
						}}
					/>
				);

				return (
					<Row gap={0.5}>
						{logo}
						<span>{name}</span>
					</Row>
				);
			},
		},
		{
			field: 'createdAt',
			headerName: 'Created at',
			flex: 1,
			renderCell({ value }) {
				return <FormattedDatetime value={value} />;
			},
		},
		{
			field: 'metadata',
			headerName: 'Metadata',
			flex: 1,
			renderCell({ value }) {
				return value ? JSON.stringify(value) : null;
			},
		},
		{ field: 'expensesCount', headerName: 'Expenses' },
	];

	return (
		<>
			<Back to={links.backoffice.ROOT} />
			<Typography variant="h2" sx={{ mb: 1 }}>
				Tools
			</Typography>

			<Row sx={{ mb: 2 }} gap={1}>
				<TextField
					label="Search"
					variant="outlined"
					size="small"
					sx={{ width: 200 }}
					onChange={(e) => setSearch(e.target.value)}
				/>

				<Button size="small" variant="contained" onClick={() => setCreateOpen(true)}>
					Create
				</Button>
			</Row>

			{isLoading && (
				<>
					<Skeleton />
					<Skeleton />
					<Skeleton />
				</>
			)}

			{data && (
				<DataGrid
					rows={data?.data ?? []}
					loading={isLoading}
					columns={columns}
					density="compact"
					rowCount={rowCount}
					paginationModel={paginationModel}
					paginationMode="server"
					onPaginationModelChange={onPaginationModelChange}
				/>
			)}

			<BackofficeToolCreate open={createOpen} onClose={() => setCreateOpen(false)} />
		</>
	);
};
