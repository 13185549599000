import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectProps } from '@mui/material';
import { Controller, FieldError } from 'react-hook-form';

const supportedCurrencies = [
	'ARS',
	'AUD',
	'BCH',
	'BGN',
	'BNB',
	'BRL',
	'BTC',
	'CAD',
	'CHF',
	'CNY',
	'CYP',
	'CZK',
	'DKK',
	'DOGE',
	'DZD',
	'EEK',
	'ETH',
	'EUR',
	'GBP',
	'GRD',
	'HKD',
	'HRK',
	'HUF',
	'IDR',
	'ILS',
	'INR',
	'ISK',
	'JPY',
	'KRW',
	'LTC',
	'LTL',
	'LVL',
	'MAD',
	'MTL',
	'MXN',
	'MYR',
	'NOK',
	'NZD',
	'PHP',
	'PLN',
	'RON',
	'RUB',
	'SEK',
	'SGD',
	'SIT',
	'SKK',
	'THB',
	'TRY',
	'TWD',
	'USD',
	'XRP',
	'ZAR',
];

type Props = {
	name: string;
} & SelectProps;

export const FormCurrencySelect = ({ name, ...textFieldProps }: Props) => {
	const labelId = `${name}-label`;

	return (
		<Controller
			name={name}
			render={({ field: { value, ...field }, fieldState: { error } }) => {
				let helperText: string | undefined;
				if (Array.isArray(error) && error.length) {
					const firstErrorMessage = (error as FieldError[]).find((e) => e?.message);
					if (firstErrorMessage?.message) helperText = firstErrorMessage.message;
				} else if (error?.message) {
					helperText = error.message;
				}

				return (
					<FormControl sx={{ width: 180 }}>
						<InputLabel id={labelId}>{textFieldProps.label}</InputLabel>
						<Select
							{...textFieldProps}
							{...field}
							value={value ?? ''}
							error={Boolean(error)}
							// helperText={helperText}
						>
							{supportedCurrencies.map((currency) => (
								<MenuItem key={currency} value={currency}>
									{currency}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				);
			}}
		/>
	);
};
