import React from 'react';
import {
	Alert,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Skeleton,
	Typography,
} from '@mui/material';
import { first } from 'lodash';
import { useSnackbar } from 'notistack';
import { FormProvider } from 'react-hook-form';

import { trpc } from '@/trpc/client';

import { ExpenseFormFields, useExpenseForm } from './backoffice-expense-dialog';

// import { ExpenseForm } from './backoffice-expense-dialog';

type Props = {
	onClose: () => void;
	open: boolean;
	invoiceId?: string;
	transactionId?: string;
};
export const BackofficeMatchingDialog = ({ onClose, open, invoiceId, transactionId }: Props) => {
	const { enqueueSnackbar } = useSnackbar();

	const invoice = trpc.backoffice.organizations.invoices.get.useQuery(
		{ id: invoiceId },
		{ enabled: Boolean(open && invoiceId) },
	);
	const transaction = trpc.backoffice.organizations.transactions.get.useQuery(
		{ id: transactionId },
		{ enabled: Boolean(open && transactionId) },
	);

	const invoiceExpenseForm = useExpenseForm({ invoiceId });
	const transactionExpenseForm = useExpenseForm({ transactionId });

	const { mutate } = trpc.backoffice.organizations.expenseMatches.create.useMutation({
		onSuccess() {
			enqueueSnackbar('Matched successfully');
			onClose();
		},
		onError() {
			enqueueSnackbar('Error matching');
		},
	});

	const handleMatch = async () => {
		let invoiceExpenseId = first(invoice.data.expenses)?.id;
		let bankPaymentTransactionExpenseId = first(transaction.data.expenses)?.id;

		if (!invoice.data.expenses?.length) {
			await invoiceExpenseForm.form.handleSubmit(invoiceExpenseForm.onSubmit)();
		}

		if (!transaction.data.expenses?.length) {
			await transactionExpenseForm.form.handleSubmit(transactionExpenseForm.onSubmit)();
		}

		mutate({ invoiceId, bankPaymentTransactionId: transactionId });
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<DialogTitle>Matching</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Typography variant="body2">Invoice</Typography>
						{invoice.isError && <Alert severity="error">Error loading invoice</Alert>}
						{invoice.isLoading && <Skeleton />}

						{invoice.data && (
							<>
								{invoice.data.expenses?.length ? (
									<Alert severity="info">Expense exists, invoice is good to match</Alert>
								) : (
									<Box sx={{ pt: 2 }}>
										<FormProvider {...invoiceExpenseForm.form}>
											<ExpenseFormFields />
										</FormProvider>
									</Box>
								)}
							</>
						)}
					</Grid>
					<Grid item xs={6}>
						<Typography variant="body2">Transaction</Typography>

						{transaction.isError && <Alert severity="error">Error loading transaction</Alert>}
						{transaction.isLoading && <Skeleton />}

						{transaction.data && (
							<>
								{transaction.data.expenses?.length ? (
									<Alert severity="info">Expense exists, payment is good to match</Alert>
								) : (
									<Box sx={{ pt: 2 }}>
										<FormProvider {...transactionExpenseForm.form}>
											<ExpenseFormFields />
										</FormProvider>
									</Box>
								)}
							</>
						)}
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Close</Button>
				<Button variant="contained" onClick={handleMatch}>
					Confirm match
				</Button>
			</DialogActions>
		</Dialog>
	);
};
