import React, { useState } from 'react';
import {
	Alert,
	Box,
	Button,
	ButtonGroup,
	Divider,
	Fab,
	IconButton,
	Slider,
	Stack,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material';
import { DataGrid, GridColDef, GridRowId, GridRowSelectionModel } from '@mui/x-data-grid';
import { first, get } from 'lodash';
import { Handshake, ThumbsUp } from 'lucide-react';
import { FormattedDate, FormattedNumber } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Back } from '@/atoms/back';
import { Icon } from '@/atoms/icon';
import { Row } from '@/atoms/row';
import { BackofficeEvalGroundTruthCol } from '@/organisms/backoffice/backoffice-eval-ground-truth-col';
import { ExpenseDialog } from '@/organisms/backoffice/backoffice-expense-dialog';
import { BackofficeMailboxAttachmentCol } from '@/organisms/backoffice/backoffice-mailbox-attachment-col';
import { BackofficeMatchingDialog } from '@/organisms/backoffice/backoffice-matching-dialog';
import { ExpensesTable } from '@/organisms/expenses-table';
import { links } from '@/paths';
import { trpc } from '@/trpc/client';
import { centsToAmount } from '@/utils/monetary';
import { border } from '@/utils/sx';
import { usePagination, usePaginationRowCount } from '@/utils/use-pagination';

import { RouterOutput } from '../../../../../functions/src/trpc/app-router';

type MessageItem = RouterOutput['backoffice']['organizations']['mailbox']['list']['data'][number];
type InvoiceItem = RouterOutput['backoffice']['organizations']['invoices']['list']['data'][number];
type TransactionItem = RouterOutput['backoffice']['organizations']['transactions']['list']['data'][number];

type Mode = 'invoices' | 'bankPayments';

export const BackofficeOrganizationsEverything = () => {
	const { id } = useParams<{ id: string }>();
	if (!id) throw new Error('No organization id');

	const [preInvoices, setPreInvoices] = useState(false);
	const [mode, setMode] = useState<Mode>('invoices');

	const [selectedMessagesIds, setSelectedMessagesIds] = useState<GridRowSelectionModel>([]);
	const [selectedInvoiceIds, setSelectedInvoiceIds] = useState<GridRowSelectionModel>([]);
	const [selectedTransactionIds, setSelectedTransactionIds] = useState<GridRowSelectionModel>([]);

	const [matchDaysOffset, setMatchDaysOffset] = useState(1);

	const [matchingOpen, setMatchingOpen] = useState(false);

	const { data: organization, isError: organizationIsError } = trpc.backoffice.organizations.get.useQuery({ id });
	const { data: expenses, isError: expensesIsError } = trpc.backoffice.organizations.expenses.list.useQuery({
		organizationId: id,
	});

	const {
		messagesData,
		messagesIsError,
		messagesIsLoading,
		messagesRowCount,
		messagesPaginationModel,
		messagesOnPaginationModelChange,
		messagesNoResultsOverlayLabel,
	} = useMessages({
		organizationId: id,
		preInvoices,
	});

	const {
		invoicesData,
		invoicesIsError,
		invoicesIsLoading,
		invoicesRowCount,
		invoicesPaginationModel,
		invoicesOnPaginationModelChange,
		invoicesNoResultsOverlayLabel,
	} = useInvoices({
		organizationId: id,
		mode,
		selectedTransactionId: first(selectedTransactionIds),
		matchDaysOffset,
	});

	const {
		transactionsData,
		transactionsIsError,
		transactionsIsLoading,
		transactionsRowCount,
		transactionsPaginationModel,
		transactionsOnPaginationModelChange,
		transactionsNoResultsOverlayLabel,
	} = useTransactions({
		organizationId: id,
		mode,
		selectedInvoiceId: first(selectedInvoiceIds),
		matchDaysOffset,
		preInvoices,
	});

	const handleSetMode = (mode: 'invoices' | 'bankPayments') => {
		setMode(mode);
		setSelectedInvoiceIds([]);
		setSelectedTransactionIds([]);
	};

	const handleSetPreInvoices = (value) => {
		setPreInvoices(value);
		handleSetMode('invoices');
	};

	const messagesOnSelectionModelChange = (newSelection: GridRowSelectionModel) => {
		setSelectedMessagesIds(newSelection);
	};

	const invoicesOnSelectionModelChange = (newSelection: GridRowSelectionModel) => {
		setSelectedInvoiceIds(newSelection);
		if (mode === 'invoices') setSelectedTransactionIds([]);
	};

	const transactionsOnSelectionModelChange = (newSelection: GridRowSelectionModel) => {
		setSelectedTransactionIds(newSelection);
		if (mode === 'bankPayments') setSelectedInvoiceIds([]);
	};

	const onMatchingOpen = () => {
		setMatchingOpen(true);
	};

	const matchEnabled = selectedInvoiceIds.length === 1 && selectedTransactionIds.length === 1;

	return (
		<Stack sx={{ pb: 6 }}>
			<Back to={links.backoffice.organizations.detail.ROOT.replace(':id', id)} />

			<Typography variant="h2" sx={{ mt: 1, mb: 2 }}>
				Everything for {organization?.name ?? ''}
			</Typography>

			<Row sx={{ mb: 0 }} gap={1}>
				<ButtonGroup variant="outlined" size="small">
					<Button variant={preInvoices ? 'contained' : 'outlined'} onClick={() => handleSetPreInvoices(true)}>
						Filter Invoices
					</Button>
					<Button
						variant={!preInvoices ? 'contained' : 'outlined'}
						onClick={() => handleSetPreInvoices(false)}
					>
						Matching
					</Button>
				</ButtonGroup>

				<ButtonGroup variant="outlined" size="small" disabled={preInvoices}>
					<Button
						variant={mode === 'invoices' ? 'contained' : 'outlined'}
						onClick={() => handleSetMode('invoices')}
					>
						Invoices
					</Button>
					<Button
						variant={mode === 'bankPayments' ? 'contained' : 'outlined'}
						onClick={() => handleSetMode('bankPayments')}
					>
						Bank Payments
					</Button>
				</ButtonGroup>

				<Box sx={{ width: 120, ml: 3 }}>
					<Slider
						disabled={preInvoices}
						value={matchDaysOffset}
						min={0}
						max={10}
						onChange={(_, value) => setMatchDaysOffset(value as number)}
					/>
					<Box sx={{ mt: -1.5 }}>{matchDaysOffset} day(s) offset</Box>
				</Box>
			</Row>

			{(organizationIsError || messagesIsError || invoicesIsError || transactionsIsError) && (
				<Alert severity="error">There was an error</Alert>
			)}

			<Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 1, position: 'relative', mb: 8 }}>
				{preInvoices && (
					<Box>
						<Typography variant="body2">Emails</Typography>
						<DataGrid
							rows={messagesData?.data ?? []}
							loading={messagesIsLoading}
							columns={messagesColumns}
							density="compact"
							rowCount={messagesRowCount}
							paginationModel={messagesPaginationModel}
							paginationMode="server"
							onPaginationModelChange={messagesOnPaginationModelChange}
							onRowSelectionModelChange={messagesOnSelectionModelChange}
							rowSelectionModel={selectedMessagesIds}
							sx={{
								...border('text.disabled', 2),
							}}
							localeText={{
								noResultsOverlayLabel: messagesNoResultsOverlayLabel,
								noRowsLabel: messagesNoResultsOverlayLabel,
							}}
							getRowHeight={() => 'auto'}
						/>
					</Box>
				)}

				<Box>
					<Row>
						<Typography variant="body2">Invoices</Typography>

						<Tooltip title="Clear selection">
							<IconButton size="small" onClick={() => invoicesOnSelectionModelChange([])}>
								<Icon name="CircleX" />
							</IconButton>
						</Tooltip>
					</Row>
					<DataGrid
						rows={invoicesData?.data ?? []}
						loading={invoicesIsLoading}
						columns={invoicesColumns}
						density="compact"
						rowCount={invoicesRowCount}
						paginationModel={invoicesPaginationModel}
						paginationMode="server"
						onPaginationModelChange={invoicesOnPaginationModelChange}
						onRowSelectionModelChange={invoicesOnSelectionModelChange}
						rowSelectionModel={selectedInvoiceIds}
						sx={{ ...border(mode === 'invoices' && !preInvoices ? 'primary.main' : 'text.disabled', 2) }}
						localeText={{
							noResultsOverlayLabel: invoicesNoResultsOverlayLabel,
							noRowsLabel: invoicesNoResultsOverlayLabel,
						}}
					/>
				</Box>

				{!preInvoices && (
					<Box>
						<Row>
							<Typography variant="body2">Payments</Typography>

							<Tooltip title="Clear selection">
								<IconButton size="small" onClick={() => transactionsOnSelectionModelChange([])}>
									<Icon name="CircleX" />
								</IconButton>
							</Tooltip>
						</Row>
						<DataGrid
							rows={transactionsData?.data ?? []}
							loading={transactionsIsLoading}
							columns={transactionsColumns}
							density="compact"
							rowCount={transactionsRowCount}
							paginationModel={transactionsPaginationModel}
							paginationMode="server"
							onPaginationModelChange={transactionsOnPaginationModelChange}
							onRowSelectionModelChange={transactionsOnSelectionModelChange}
							rowSelectionModel={selectedTransactionIds}
							sx={{
								...border(
									mode === 'bankPayments' && !preInvoices ? 'primary.main' : 'text.disabled',
									2,
								),
							}}
							localeText={{
								noResultsOverlayLabel: transactionsNoResultsOverlayLabel,
								noRowsLabel: transactionsNoResultsOverlayLabel,
							}}
						/>
					</Box>
				)}

				{!preInvoices && (
					<Box sx={{ position: 'absolute', left: '50%', top: '40%', ml: '-24px' }}>
						<Tooltip title="Click to match invoice and bank payment transaction">
							<Fab color="primary" disabled={!matchEnabled} onClick={onMatchingOpen}>
								<Handshake />
							</Fab>
						</Tooltip>
					</Box>
				)}
			</Box>

			<Divider />
			<Typography variant="body2" sx={{ mt: 1 }}>
				Expenses
			</Typography>

			{expenses && <ExpensesTable variant="backoffice" data={expenses} />}

			<BackofficeMatchingDialog
				open={matchingOpen}
				onClose={() => setMatchingOpen(false)}
				invoiceId={first(selectedInvoiceIds) as string | undefined}
				transactionId={first(selectedTransactionIds) as string | undefined}
			/>
		</Stack>
	);
};

const useMessages = ({ organizationId, preInvoices }: { organizationId: string; preInvoices: boolean }) => {
	const { page, pageSize, paginationModel, onPaginationModelChange } = usePagination({ defaultPageSize: 20 });

	const { data, isError, isLoading } = trpc.backoffice.organizations.mailbox.list.useQuery({
		organizationId,
		page,
		pageSize,
	});

	const rowCount = usePaginationRowCount(data?.pagination.count);

	return {
		messagesData: data,
		messagesIsError: isError,
		messagesIsLoading: isLoading,
		messagesRowCount: rowCount,
		messagesPaginationModel: paginationModel,
		messagesOnPaginationModelChange: onPaginationModelChange,
		messagesNoResultsOverlayLabel: '',
	};
};

const useInvoices = ({
	organizationId,
	mode,
	selectedTransactionId,
	matchDaysOffset,
}: {
	organizationId: string;
	mode: Mode;
	selectedTransactionId?: GridRowId;
	matchDaysOffset: number;
}) => {
	const { page, pageSize, paginationModel, onPaginationModelChange } = usePagination({ defaultPageSize: 20 });

	const { data, isError, isLoading } = trpc.backoffice.organizations.invoices.list.useQuery({
		organizationId,
		page,
		pageSize,
		matchBankPaymentTransactionId:
			mode === 'bankPayments' && selectedTransactionId ? String(selectedTransactionId) : undefined,
		matchDaysOffset: mode === 'bankPayments' && selectedTransactionId ? matchDaysOffset : undefined,
	});

	const disabledNoSelection = mode === 'bankPayments' && !selectedTransactionId;

	const invoicesData = disabledNoSelection ? undefined : data;

	const invoicesRowCount = usePaginationRowCount(invoicesData?.pagination.count);

	return {
		invoicesData,
		invoicesIsError: isError,
		invoicesIsLoading: isLoading,
		invoicesRowCount,
		invoicesPaginationModel: paginationModel,
		invoicesOnPaginationModelChange: onPaginationModelChange,
		invoicesNoResultsOverlayLabel: disabledNoSelection ? 'Select bank payment transaction first' : '',
	};
};

const useTransactions = ({
	organizationId,
	mode,
	selectedInvoiceId,
	matchDaysOffset,
	preInvoices,
}: {
	organizationId: string;
	mode: Mode;
	selectedInvoiceId?: GridRowId;
	matchDaysOffset: number;
	preInvoices: boolean;
}) => {
	const { page, pageSize, paginationModel, onPaginationModelChange } = usePagination({ defaultPageSize: 20 });

	const { data, isError, isLoading } = trpc.backoffice.organizations.transactions.list.useQuery({
		organizationId,
		page,
		pageSize,
		matchInvoiceId: mode === 'invoices' && selectedInvoiceId ? String(selectedInvoiceId) : undefined,
		matchDaysOffset: mode === 'invoices' && selectedInvoiceId ? matchDaysOffset : undefined,
	});

	const disabledPreInvoicesMode = preInvoices;
	const disabledNoSelection = mode === 'invoices' && !selectedInvoiceId;

	const transactionsData = disabledNoSelection || disabledPreInvoicesMode ? undefined : data;

	const transactionsRowCount = usePaginationRowCount(transactionsData?.pagination.count);

	return {
		transactionsData,
		transactionsIsError: isError,
		transactionsIsLoading: isLoading,
		transactionsRowCount,
		transactionsPaginationModel: paginationModel,
		transactionsOnPaginationModelChange: onPaginationModelChange,
		transactionsNoResultsOverlayLabel: disabledPreInvoicesMode
			? 'Pre invoices mode'
			: disabledNoSelection
				? 'Select invoice first'
				: '',
	};
};

const messagesColumns: GridColDef<MessageItem>[] = [
	{
		field: 'subject',
		headerName: 'Subject',
	},
	{
		field: 'receivedAt',
		headerName: 'Received At',
		width: 160,
		renderCell({ value }) {
			return <FormattedDate value={value} />;
		},
	},
	{
		field: 'attachments',
		headerName: 'Attachments',
		width: 200,
		renderCell({ row: { attachments } }) {
			return <BackofficeMailboxAttachmentCol attachments={attachments} />;
		},
	},
	{
		field: 'evalGroundTruth',
		headerName: 'Eval Ground Truth',
		width: 180,
		renderCell({ row: { id, evalGroundTruth } }) {
			return <BackofficeEvalGroundTruthCol emailMessageId={id} value={evalGroundTruth} />;
		},
	},
];

const invoicesColumns: GridColDef<InvoiceItem>[] = [
	{
		field: 'supplierName',
		headerName: 'Supplier',
		flex: 1,
	},
	{
		field: 'totalAmount',
		headerName: 'Amount',
		width: 100,
		renderCell: ({ row: { totalAmountUsd } }) => (
			<FormattedNumber value={centsToAmount(Number(totalAmountUsd))} style="currency" currency="USD" />
		),
	},
	{
		field: 'invoiceDate',
		headerName: 'Date',
		width: 140,
		renderCell: (params) => <FormattedDate value={params.row.invoiceDate} />,
	},
	{
		field: 'expenses',
		headerName: '',
		renderCell: ({ row: { expenses, id } }) => {
			return <BackofficeExpenseCol expenses={expenses} invoiceId={id} />;
		},
	},
];

const transactionsColumns: GridColDef<TransactionItem>[] = [
	{
		field: 'additionalInformation',
		headerName: 'Info',
		flex: 1,
		renderCell({ value, row: { id, metadata } }) {
			const label = String(
				get(metadata, 'remittanceInformationUnstructuredArray') ?? get(metadata, 'additionalInformation'),
			);
			return label;
		},
	},
	{
		field: 'amount',
		headerName: 'Amount',
		width: 100,
		renderCell: ({ row: { amountUsd } }) => (
			<FormattedNumber value={centsToAmount(Number(amountUsd))} style="currency" currency="USD" />
		),
	},
	{
		field: 'data',
		headerName: 'Date',
		width: 140,
		renderCell: ({ row: { date } }) => <FormattedDate value={date} />,
	},
	{
		field: 'expenses',
		headerName: '',
		renderCell: ({ row: { expenses, id } }) => {
			return <BackofficeExpenseCol expenses={expenses} transactionId={id} />;
		},
	},
];

type BECProps = {
	invoiceId?: string;
	transactionId?: string;
	expenses: InvoiceItem['expenses'] | TransactionItem['expenses'];
};

const BackofficeExpenseCol = ({ expenses, invoiceId, transactionId }: BECProps) => {
	const [open, setOpen] = useState(false);

	const { palette } = useTheme();

	const onClose = () => setOpen(false);

	const expense = first(expenses);
	const expenseId = expense?.id;
	const expenseMatches = expense?.matches;

	const hasMatches = expenseMatches > 0;

	return (
		<Row>
			<IconButton
				size="small"
				onClick={(e) => {
					e.preventDefault();
					setOpen(true);
				}}
			>
				{expenseId ? (
					<ThumbsUp stroke={palette.primary.main} strokeWidth={3} width={16} />
				) : (
					<ThumbsUp width={16} />
				)}
			</IconButton>

			{expenseId && (
				<IconButton size="small">
					{hasMatches ? (
						<Handshake stroke={palette.primary.main} strokeWidth={3} width={16} />
					) : (
						<Handshake width={16} />
					)}
				</IconButton>
			)}

			<ExpenseDialog
				expenseId={expenseId}
				invoiceId={invoiceId}
				transactionId={transactionId}
				open={open}
				onClose={onClose}
			/>
		</Row>
	);
};
