import React, { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

import { FormCurrencySelect } from '@/atoms/form-currency-select';
import { FormTextField } from '@/atoms/form-text-field';
import { Row } from '@/atoms/row';
import { BackofficeToolSelect } from '@/molecules/backoffice/backoffice-tool-select';
import { trpc } from '@/trpc/client';
import { amountToCents, centsToAmount } from '@/utils/monetary';
import { preprocessFloat } from '@/utils/zod';

type EDProps = {
	invoiceId?: string;
	transactionId?: string;
	expenseId?: string;
	open: boolean;
	onClose: () => void;
};

export const expenseFormSchema = z.object({
	amount: preprocessFloat(z.number()),
	currency: z.string(),
	amountUsd: preprocessFloat(z.number()),
	vendor: z.string(),
	date: z.string(),
	toolId: z.string().optional(),
});
export type ExpenseFormSchema = z.infer<typeof expenseFormSchema>;

export const useExpenseForm = ({
	invoiceId,
	transactionId,
	onSuccess,
	onError,
}: {
	invoiceId?: string;
	transactionId?: string;
	onSuccess?: () => void;
	onError?: () => void;
}) => {
	const transaction = trpc.backoffice.organizations.transactions.get.useQuery(
		{ id: transactionId },
		{ enabled: Boolean(open && transactionId) },
	);
	const invoice = trpc.backoffice.organizations.invoices.get.useQuery(
		{ id: invoiceId },
		{ enabled: Boolean(open && invoiceId) },
	);

	const form = useForm<ExpenseFormSchema>({
		resolver: zodResolver(expenseFormSchema),
	});

	const { mutateAsync } = trpc.backoffice.organizations.expenses.create.useMutation({ onSuccess, onError });

	useEffect(() => {
		if (transaction.data) {
			form.reset({
				amountUsd: centsToAmount(Number(transaction.data.amountUsd)) * -1,
				currency: transaction.data.currency,
				amount: centsToAmount(Number(transaction.data.amount)) * -1,
				vendor:
					String(
						get(transaction.data.metadata, 'remittanceInformationUnstructuredArray') ??
							get(transaction.data.metadata, 'additionalInformation'),
					) || undefined,
				date: transaction.data.date,
			});
		}
		if (invoice.data) {
			form.reset({
				amountUsd: centsToAmount(Number(invoice.data.totalAmountUsd)),
				currency: invoice.data.currency,
				amount: centsToAmount(Number(invoice.data.totalAmount)),
				vendor: invoice.data.supplierName,
				date: invoice.data.invoiceDate,
			});
		}
	}, [transaction.data, invoice.data]);

	const onSubmit = async ({ amount, amountUsd, ...data }: ExpenseFormSchema) => {
		return mutateAsync({
			amount: amountToCents(amount),
			amountUsd: amountToCents(amountUsd),
			invoiceId,
			bankPaymentTransactionId: transactionId,
			...data,
		});
	};

	return { invoice, transaction, onSubmit, form };
};

export const ExpenseDialog = ({ invoiceId, transactionId, expenseId, open, onClose }: EDProps) => {
	const { enqueueSnackbar } = useSnackbar();

	const { invoice, transaction, onSubmit, form } = useExpenseForm({
		invoiceId,
		transactionId,
		onSuccess() {
			onClose();
			enqueueSnackbar('Expense created');
		},
		onError() {
			enqueueSnackbar('Error creating expense', { variant: 'error' });
		},
	});

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle>Expense</DialogTitle>
			<FormProvider {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)}>
					<DialogContent>
						<Alert sx={{ mb: 3, mt: -2 }} variant="outlined" severity="info" icon={false}>
							{expenseId ? 'Edit' : 'Create'} expense from{' '}
							{invoiceId ? 'invoice' : 'bank payment transaction'} {invoiceId ?? transactionId}
						</Alert>

						<ExpenseFormFields />
					</DialogContent>
					<DialogActions>
						<Button onClick={onClose}>Close</Button>
						<Button type="submit" variant="contained">
							Create
						</Button>
					</DialogActions>
				</form>
			</FormProvider>
		</Dialog>
	);
};

export const ExpenseFormFields = () => {
	return (
		<Stack gap={2}>
			<Row gap={1}>
				<FormTextField name="amount" label="Amount" />
				<FormCurrencySelect name="currency" label="Currency" />
			</Row>
			<FormTextField name="amountUsd" label="Amount USD" />
			<FormTextField name="vendor" label="Vendor" />
			<FormTextField name="date" label="Date" />
			<Controller
				name="toolId"
				render={({ field: { value, onChange } }) => {
					return <BackofficeToolSelect valueId={value} onChange={onChange} />;
				}}
			/>
		</Stack>
	);
};
