import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
	Avatar,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Tooltip,
	Typography,
} from '@mui/material';
import { RefreshCw } from 'lucide-react';
import { useSnackbar } from 'notistack';
import { Controller, FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { z } from 'zod';

import { FormTextField } from '@/atoms/form-text-field';
import { Row } from '@/atoms/row';
import { trpc } from '@/trpc/client';
import { border } from '@/utils/sx';

type Props = {
	open: boolean;
	onClose: (createdToolId?: string) => void;
};

const schema = z.object({
	name: z.string(),
	logoUrl: z.string().optional(),
});

type Schema = z.infer<typeof schema>;

export const BackofficeToolCreate = ({ open, onClose }: Props) => {
	const { enqueueSnackbar } = useSnackbar();

	const utils = trpc.useUtils();
	const { mutate } = trpc.backoffice.tools.create.useMutation({
		onSuccess: ({ id }) => {
			enqueueSnackbar('Tool created successfully!');
			onClose(id);
			utils.backoffice.tools.list.invalidate();
		},
		onError: () => {
			enqueueSnackbar('Tool creation failed');
		},
	});

	const form = useForm<Schema>({
		resolver: zodResolver(schema),
	});

	const handleSubmit = (data: Schema) => {
		mutate(data);
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle>Create Tool</DialogTitle>

			<FormProvider {...form}>
				<form
					onSubmit={(event) => {
						event.preventDefault();
						event.stopPropagation();

						form.handleSubmit(handleSubmit)(event);
					}}
				>
					<DialogContent>
						<FormTextField name="name" label="Name" fullWidth />

						<FormToolLogoField form={form} />
					</DialogContent>
					<DialogActions>
						<Button onClick={() => onClose()}>Cancel</Button>
						<Button variant="contained" type="submit">
							Create
						</Button>
					</DialogActions>
				</form>
			</FormProvider>
		</Dialog>
	);
};

const FormToolLogoField = ({ form }: { form: UseFormReturn }) => {
	const { mutateAsync } = trpc.backoffice.tools.searchLogo.useMutation();

	return (
		<Controller
			name="logoUrl"
			render={({ field: { value, onChange }, formState }) => {
				const handleClick = () => {
					const name = form.getValues('name');
					if (!name) return;

					mutateAsync({ search: name }).then(
						(brand) => {
							if (brand) {
								onChange(brand.icon);
							}
						},
						() => {},
					);
				};

				return (
					<>
						<Row sx={{ mt: 1 }} gap={0.25}>
							<Typography variant="h6">Logo</Typography>

							<Tooltip title="Fetch logo">
								<IconButton onClick={handleClick}>
									<RefreshCw />
								</IconButton>
							</Tooltip>
						</Row>

						<Avatar
							alt=""
							src={value}
							sx={{
								'& img': { objectFit: 'contain' },
								'width': '4rem',
								'height': '4rem',
								...border('text.disabled'),
							}}
						/>
					</>
				);
			}}
		/>
	);
};
